.projectsContainer {
    position: relative;
    margin: 0 auto;
    display: flex;
    width: 100vw;
}

.allProjects {
    position: relative;
    min-width: 100%;
    height: 100dvh;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projectsBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    flex-grow: 1;
}

.nav {
    display: flex;
    justify-content: flex-end;
    padding: 50px 50px 0px 50px;
    width: 100%;
    img {
        position: relative;
        z-index: 10;
        cursor: pointer;
        height: 36px;
        transform: rotate(180deg);
        // margin-right: 50px;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 50px;
    height: 40px;
}

@media (max-width: 900px) {
    .nav {
        img {
            height: 22px;
        }
    }
    .allProjects {
        padding: 0;
    }
}

@media (max-width: 500px) {
    .nav {
        img {
            height: 17px;
        }
        padding: 25px 25px 0 25px;
    }
    .footer {
        padding: 0 25px;
    }
}