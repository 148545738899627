@use "../../styles/colors.scss" as *;
.workSection {
    width: 200vw;
    display: flex;
    transition: all 0.7s ease;
    &.infoVisible {
        transform: translateX(-50%);
    }
}

.inner {
    height: 100%;
}