body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter", sans-serif; */
  font-family: "Work Sans", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@1,400;1,700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=PT+Serif:ital,wght@1,400;1,700&display=swap"); */
/**/
h1 {
  /* font-family: "Open Sans", sans-serif; */
}

/* @font-face { */
/*   font-family: "LeMurmure"; */
/*   src: url("../src/assets/fonts/FontsFree-Net-le-murmure.ttf") */
/*     format("truetype"); */
/*   font-weight: normal; */
/*   font-style: normal; */
/* } */
/**/
/* @font-face { */
/*   font-family: "Porsche"; */
/*   src: url("../src/assets/fonts/PorshaRichela.ttf") format("truetype"); */
/*   font-weight: normal; */
/*   font-style: normal; */
/* } */

/* @font-face { */
/*   font-family: "Work Sans"; */
/*   src: url("../src/assets/fonts/WorkSans-VariableFont_wght.ttf") */
/*     format("truetype"); */
/*   font-weight: normal; */
/*   font-style: normal; */
/* } */
