@use "../../styles/colors.scss" as *;
.projectContainer {
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    height: 20%;
    display: flex;
    align-items: center;
    &.expanded {
        height: 100%;
    }
    &.otherExpanded {
        height: 35px;
    }
    mix-blend-mode: difference !important;
}

.projectTitleContainer {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 0 50px;
    //bullet
    img {
        width: 30px;
        margin-right: 30px;
        &.rotate {
            transform: rotate(180deg);
        }
    }
    &.expanded {
        height: 50px;
    }
    &.otherExpanded {
        height: 35px;
        pointer-events: none;
        img {
            width: 45px;
        }
    }
    &:hover {
        .arrow {
            img {
                animation: in 0.35s forwards;
                animation-timing-function: cubic-bezier(.95, 0, .66, 1)
            }
        }
    }
}

.otherIsActive {
    opacity: 0.5;
}

.ellipse {
    position: relative;
    margin-left: auto;
    opacity: 0;
    transition: all 0.25s;
    transition-timing-function: cubic-bezier(.86, .02, .55, .95);
    border: 2px solid $green3;
    border-radius: 50%;
    height: 50px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &.visible {
        opacity: 1;
    }
}

@keyframes out {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(200%);
    }
}

@keyframes in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.arrow {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
    overflow: hidden;
    img {
        transform: translateX(-100%);
        width: 40px;
        animation: out 0.35s forwards;
        animation-timing-function: cubic-bezier(.95, 0, .66, 1)
    }
    &.visible {
        opacity: 1;
    }
}

@media (max-width: 900px) {
    .otherIsActive {
        opacity: 1;
    }
    .projectTitleContainer {
        img {
            width: 20px;
            margin-right: 20px;
        }
    }
}

@media (max-width: 500px) {
    .projectTitleContainer {
        padding: 0 25px;
    }
}