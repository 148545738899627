@use "../../styles/colors.scss" as *;

:root {
    --mouse-x: 0;
    --mouse-y: 0;
}

.bioSection {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100vw;
    margin: 0 auto;

    @media (max-width: 420px) {
        // align-items: flex-end;
        // padding-bottom: 100px;
    }
}

@keyframes blur {
    from {
        filter: blur(10px);
    }

    to {
        filter: blur(0px);
    }
}

.bioContainer {
    padding: 0 50px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    line-height: 40px;



    *:not(.row, .bio) {
        animation: blur 2.5s ease;
    }

    .name {
        animation: blur 1s ease;
    }

    .dev,
    .works {
        animation: blur 1.75s ease;
    }


    h1 {
        align-items: center;
        display: flex;
    }

    @media (max-width: 1000px) {
        line-height: 30px;

        * {
            animation: unset !important;
        }
    }

    @media (max-width: 750px) {
        display: block;
    }

    @media (max-width: 420px) {
        padding: 0 25px;
    }
}

.bio {
    width: 50%;
    margin-right: 50px;

    h1,
    h2,
    h3,
    span {
        font-weight: 500;
        font-size: 30px;
        min-width: fit-content;
    }

    a {
        font-style: italic;
        margin-left: 10px;
        font-weight: 300;
        display: flex;
        line-height: 30px;

        &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }

    @media (max-width: 1310px) {
        min-width: max-content;
        width: unset;
    }

    @media (max-width: 1000px) {

        h1,
        h2 {
            font-size: 20px;
        }

        a {
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
        }
    }

    @media (max-width: 750px) {
        h1 {
            font-weight: 600;
        }

        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 10px;
        width: 100%;
    }
}

@keyframes out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(200%);
    }
}

@keyframes in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.bullet {
    height: 23px;
    aspect-ratio: 1;
    aspect-ratio: 1;
    background-color: #947CEC;
    border-radius: 50%;
    margin-left: 1px;
    margin-right: 2px;
    position: relative;

    &.__works {
        // background-color: #04F930;
        background-color: unset;
        height: 24px;
        margin-right: 0px;
    }

    // top: 1px;
}

.works {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;
    margin-top: 40.5px;


    h2 {
        font-size: 30px;
        font-weight: 500;
        display: flex;
        align-items: center;

        .arrow {
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-left: 10px;

            img {
                height: 30px;
                margin-left: 10px;
                transform: translateX(-100%);
                width: 40px;
                animation: out 0.35s forwards;
                animation-timing-function: cubic-bezier(.95, 0, .66, 1);
                opacity: 0;

                &.show {
                    opacity: 1;
                }
            }

            &.hovered {
                animation: in 0.35s forwards;
            }
        }
    }

    h3,
    .slash {
        font-weight: 200;
        font-size: 30px;
    }

    h3 {
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        align-items: flex-end;

        &.otherHovered {
            opacity: 0.3;
        }
    }

    @media (max-width: 1310px) {
        max-width: 500px;
        margin-right: auto;
    }

    @media (max-width: 1000px) {
        max-width: unset;
        margin-top: 30px;

        h2 {
            font-size: 20px;

            .arrow {
                display: none;
            }
        }

        h3 {
            font-size: 20px;

            &.otherHovered {
                opacity: 1;
            }
        }

        .slash {
            font-size: 20px;
            font-weight: 200;
        }
    }

    @media (max-width:750px) {
        margin-top: 10px;
        justify-content: flex-end;
        text-wrap: balance;

        h2 {
            font-weight: 600;
            // flex-basis: 100%;
            text-align: right;
            display: block;
            width: 100%;
            margin-right: 0;
        }

        h3 {
            font-style: italic;
            font-weight: 300;
            font-size: 16px;
        }

        h3:last-child {}
    }
}

.links {
    display: flex;
    margin-bottom: 20px;

    a {
        margin-left: 10px;
        text-decoration: underline;
    }
}





.row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .imageContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 10px;

        svg {
            height: 45px;
            margin-right: 10px;
        }
    }

    @media (max-width: 750px) {
        // width: unset;
        text-align: right;
    }
}
