.imageContainer {
    position: absolute;
    // z-index: -10000 !important;
    left: calc(var(--mouse-x) - 15px);
    top: calc(var(--mouse-y) - 30px);
    pointer-events: none;
    transform: translate(-50%, -50%);
    height: 200px;
    width: 200px;
    overflow: hidden;
    overflow: hidden;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 101%;
        margin: 0 auto;
    }
    @media (max-width: 1000px) {
        display: none;
    }
}

.prevImage {
    // transition: all 0.75s ease;
    // transform: translate(-50%, -20%);
    // z-index: 2;
}

@keyframes goUp {
    from {
        transform: translate(-50%, 50%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}

@keyframes exit {
    from {
        transform: translate(-50%, -50%);
    }
    to {
        transform: translate(-50%, -160%);
    }
}

@keyframes goDown {
    from {
        transform: translate(-50%, -50%);
    }
    to {
        transform: translate(-50%, -20%);
    }
}

.animate {
    animation: 0.35s ease goUp forwards;
    animation-timing-function: cubic-bezier(.95, 0, .66, 1)
}

.animate.activeImage {
    // z-index: 4 !important;
}

.exit {
    animation: 0.5s ease exit forwards;
    animation-timing-function: cubic-bezier(.84, .03, .44, .95)
}

@for $i from 0 through 20 {
    .imageContainer:nth-child(#{$i}) {
        z-index: #{$i}
    }
}