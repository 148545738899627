$black: #050505;
$grey1: #DCDEE5;
$grey2: #EFECE5;
$grey3: #8E8988;
$green: #02FF57;
$green2: #D1FE2F;
$green3: #69bfa6;
$yellow: #FEF222;
$red: #FF0506;
$orange: #ff763f;
$coral: #FF8474;