@use "../../styles/colors.scss" as *;
.wordContainer {
    position: relative;
    width: calc(max-content + 30px);
    overflow: hidden;
    // margin-right: 30px;
    h2 {
        font-weight: 200;
        font-size: 40px;
        // letter-spacing: 4px;
        mix-blend-mode: difference;
        color: rgb(77, 77, 77);
        // font-family: 'PT Serif', serif !important;
    }
}

.space {
    width: 10px;
}

.newWord {
    position: absolute;
    // transform: translateY(-8%);
    font-style: italic;
    // letter-spacing: -px !important;
}

.letter {
    position: relative;
    display: inline-block;
    transition: all 0.25s;
    transition-timing-function: cubic-bezier(.86, .02, .55, .95);
    z-index: 20;
    // font-size: 3px;
    // font-family: 'IBM Plex Serif', serif;
}

.letter_1 {}

.isHovered {
    transform: translateY(-100%);
}

@for $i from 0 through 20 {
    .letter_#{$i} {
        transition-delay: calc(#{$i}s * 0.01);
    }
}

@media (max-width: 900px) {
    // prevent animation on mobile
    .isHovered {
        transform: translateX(0);
    }
    .wordContainer {
        h2 {
            // font-size: 20px;
            border: 1px solid red;
            // font-weight: 300;
        }
    }
}

@media (max-width: 750px) {
    // transform: translateY(-8%);
    .wordContainer {
        transform: translateY(2px);
        h2 {
            font-size: 25px;
        }
    }
    .space {
        width: 5px;
    }
}

@media (max-width: 500px) {
    .wordContainer {
        h2 {
            font-size: 25px;
        }
    }
}