.navContainer {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    p {
        // color: #b12f28;
        font-weight: 400;
        // font-family: 'Inter', sans-serif;
        font-style: italic;
        letter-spacing: 1px;
    }
}