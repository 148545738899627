    @use "../../styles/colors.scss" as *;

    .infoContainer {
        padding: 50px 50px 0 50px;
        position: relative;
        z-index: 30;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100dvh;
        width: 100vw;
        overflow-y: scroll;
    }

    .imageContainer {
        min-width: 100%;
        // max-width: fit-content;
        margin-left: auto;
        align-self: flex-end;
        margin: 0 auto;
        margin-top: auto;
        margin-bottom: 35px;
        max-height: 70dvh;
        display: flex;
        justify-content: center;
        aspect-ratio: 1.566;
        position: relative;

        .blurLoad {
            background-size: cover;
            background-position: center;
            filter: blur(10px);
            transition: filter 0.2s ease;

            &.loaded {
                filter: blur(0);
                background-image: unset !important;
            }
        }

        img,
        video {
            max-height: 70dvh;
            opacity: 0;
            min-width: 100%;

            &.loaded {
                opacity: 1;
            }
        }
    }

    .info {
        width: 100%;
        margin: 0 auto;
        margin-bottom: auto;
        position: relative;

        // color: rgb(77, 77, 77);
        .nav {
            span {
                cursor: pointer;
            }

            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;

            img {
                position: relative;
                z-index: 10;
                cursor: pointer;
                height: 36px;
                transform: rotate(180deg);
            }
        }

        .infoInner {
            max-width: 1050px;
            width: fit-content;
            margin: 0 auto;

            .name {
                // width: calc(100% + 20px);
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                align-items: center;

                h3 {
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 40px;
                }

                a {
                    line-height: 40px;
                    font-weight: 500;
                    font-size: 30px;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 3px;
                    }

                    // visit site arrow
                    img {
                        height: 18px;
                        transform: rotate(-90deg);
                        margin-left: 5px;
                        position: relative;
                        top: 1px;
                        // margin-top: 5px;
                        // display: none;
                    }
                }
            }

            .date {
                font-weight: 300;
                font-style: italic;
                // margin-bottom: 8px;
            }

            .tools {
                font-weight: 200;
                font-size: 30px;
                line-height: 45px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        height: 40px;
        padding: 0 50px;
    }

    @media (max-width: 1439px) {
        .imageContainer {
            margin-top: 50px;
            height: 50dvh;
            display: flex;
            justify-content: center;

            img,
            video {
                height: 100%;
                width: auto;
                margin: 0 auto;
            }
        }

        .info {
            .infoInner {
                width: auto;

                .name {
                    width: 100%;

                    h3 {
                        font-size: 30px;
                    }
                }

                .tools {
                    // font-size: 20px;
                }
            }
        }

        .footer {
            padding: 0;
        }
    }

    @media (max-width: 900px) {
        .imageContainer {
            width: 100%;
            display: block;
            height: auto;

            img,
            video {
                width: 100%;
                height: auto;
                margin: unset;
            }
        }

        .info {
            .nav {
                img {
                    height: 22px;
                }
            }

            .infoInner {
                .name {
                    h3 {
                        // font-weight: 200;
                        // letter-spacing: 4px
                        display: flex;
                        font-size: 20px;
                        flex-wrap: wrap;
                        line-height: 30px;

                        .role {
                            flex-basis: 100%;
                            font-weight: 200;
                            // margin-top: 7px;
                        }

                        .dash {
                            // display: none;
                            opacity: 0;
                            margin-right: auto;
                        }
                    }

                    a {
                        font-size: 20px;

                        // visit site arrow 
                        img {
                            height: 12px;
                        }
                    }
                }

                .date {
                    margin-bottom: 3px;
                }

                .tools {
                    font-weight: 200;
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .info {
            .infoInner {
                .name {
                    h3 {
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: 600;

                        .role {
                            flex-basis: 100%;
                            font-weight: 200;
                            margin-top: 7px;
                        }
                    }

                    a {
                        font-size: 14px;
                        // text-decoration: underline;
                        font-style: italic;

                        // visit site arrow;
                        img {
                            height: 9px;
                            top: 1px;

                        }

                        .site {
                            display: none;
                        }
                    }
                }

                .date {
                    margin-bottom: 8px;
                    font-weight: 200;
                }

                .tools {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .infoContainer {
            padding: 25px 25px 0 25px;
        }

        .info {
            .nav {
                img {
                    height: 17px;
                }
            }
        }
    }
